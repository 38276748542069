<template>
    <div class="flex flex-col h-screen">
        <NavbarComponent />
        <main class="block relative mt-20">
            <section class="mt-3 bg-white">
                <div class="p-2">
                    <h2 class="text-6xl post-gradient mb-4 mt-10 text-center">{{ $t('Blog.blogPost')}}</h2>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-5 md:p-3 p-6">
                        <router-link :to="{ name : 'blog-details-page', params : { id : blog.uuid, slug : blog.slug }}" v-for="blog in blogs.results" :key="blog.uuid" class="flex flex-col custom-border rounded-md shadow-sm mb-3">
                            <div class="bg-gray-300 w-full h-full border-b">
                                <img  class="w-full" :src="blog.image" />
                            </div>
                            <div class="flex flex-col p-1">
                                <h2 class="text-2xl text-gray-900">{{ getTitle(blog) }}</h2>
                                <div class="flex justify-end"><span class="text-blue-700">{{ blog.published_at }}</span></div>
                            </div>
                        </router-link>
                    </div>
                    <div class="flex justify-end mt-4 mb-5">
                        <paginate
                        v-model="page"
                        :page-count="blogs.total_pages"
                        :click-handler="firePagination"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :page-class="'page-item c-mr-1 border-unset'"
                        prev-link-class='page-link c-mr-1 border-unset'
                        next-link-class='page-link c-mr-1 border-unset'
                        next-class='next-item'
                        prev-class='prev-item'
                        page-link-class='page-link c-mr-1 border-unset'
                        active-class='active c-mr-1 border-unset'
                        :container-class="'pagination  b-pagination'">
                        </paginate>
                        <!-- <div class="flex justify-between items-center space-x-4 pagination">
                            <span class="text-gray-400">Prev</span>
                            <ul class="flex space-x-4 items-center">
                                <li class="cursor-pointer" :class="i === 1 ? 'flex justify-center items-center rounded-full h-8 w-8 bg-theme-sidebar text-white' : ''" v-for="i in 4" :key="i">{{ i }}</li>
                            </ul>
                            <span class="text-gray-900">Next</span>
                        </div> -->
                    </div>
                </div>
            </section>

        </main>
        <FooterComponent />
    </div>
</template>

<script>
    import NavbarComponent from './components/Navbar.vue'
    import FooterComponent from './components/Footer.vue'
    import FaqRenderVue from './custom/AccordionFaq.vue'
    import useAppConfig from '@core/app-config/useAppConfig';
    import Paginate from 'vuejs-paginate'

    export default {
        name : 'BlogPage',
        components : { 
            NavbarComponent, 
            FooterComponent, 
            'paginate' : Paginate,
            FaqRenderVue 
        },
        data () {
            return {
                config: useAppConfig(),
                blogs : { results : [], total_pages : 1},
                queryParams : { limit : 100 },
                page : 1
            }
        },
        computed :{
            getLang() {
                return this.config.lang.value;
            },
            title() {
                if (this.getLang == '') {
                    return this.swedishLang ? 'swe_title' : 'eng_title';
                } else return this.getLang == 'sv' ? 'swe_title' : 'eng_title';
            },
            preamble() {
                if (this.getLang == '') {
                    return this.swedishLang ? 'swe_preamble' : 'eng_preamble';
                } else return this.getLang == 'sv' ? 'swe_preamble' : 'eng_preamble';
            },
        },
        mounted () {
            this.getLandingBlog()
        },
        methods : {
            getLandingBlog () {
                this.$useJwt.customBlog({ URL : '', method : 'get'}, { params : this.queryParams })
                .then(res => {
                    this.blogs = {...this.blogs, total_pages : 1, results : res.data.results}
                })
            },
            getTitle (data) {
                return data[this.title]
            },
            firePagination(number) {
                this.page = number
                this.queryParams.page = number;
                this.getLandingBlog();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .post-gradient {
        font-style: normal;
        font-weight: 794;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        background: linear-gradient(266.01deg, #0B0875 12.33%, #00D090 113.67%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
</style>